import { useQueryClient } from "react-query";
import { useToast } from "../../components/ui/toast-context-provider";
import { useTranslation } from "react-i18next";
import { useDevicesQuery } from "../../queries/devices.query";
import { DataTable } from "primereact/datatable";
import { useCallback, useMemo, useState } from "react";
import { Column, ColumnFilterElementTemplateOptions } from "primereact/column";
import { Device } from "../../queries/models/device.model.ts";
import { Panel } from "primereact/panel";
import { endOfMonth, startOfMonth, subDays } from "date-fns";
import { useGetDataReadingsForSeriesQuery } from "../../queries/device-readings.query";
import { Calendar } from "primereact/calendar";
import { DevicesChartComponent } from "./DevicesChart";
import { MultiSelect } from "primereact/multiselect";
import { FilterMatchMode } from "primereact/api";
import Enumerable from "linq";

export function Dashboard() {
  const { t } = useTranslation();
  const toast = useToast();
  const queryClient = useQueryClient();

  const [filters, setFilters] = useState({
    tag: { value: null, matchMode: FilterMatchMode.CONTAINS },
    "recorder.id": { value: null, matchMode: FilterMatchMode.IN },
    uoM: { value: null, matchMode: FilterMatchMode.IN },
  });
  const [selectedDevices, setSelectedDevices] = useState<Device[]>([]);
  const [dateRange, setDateRange] = useState<Date[]>([
    startOfMonth(new Date()),
    endOfMonth(new Date()),
  ]);

  const devicesQuery = useDevicesQuery();
  const devicesReadingsQuery = useGetDataReadingsForSeriesQuery(
    dateRange[0],
    dateRange[1],
    selectedDevices.map((d) => d.id)
  );

  const selectedTotalValue = useMemo(() => {
    return devicesReadingsQuery.data?.reduce((acc, cur) => acc + cur.value, 0);
  }, [devicesReadingsQuery.data]);

  const selectedLastReadingTotalValue = useMemo(() => {
    const lastReadings = Enumerable.from(devicesQuery.data ?? [])
      .groupBy((x) => x.id)
      .select((x) => {
        const lastReading = Enumerable.from(devicesReadingsQuery.data ?? [])
          .where((r) => r.deviceId === x.key())
          .orderByDescending((r) => r.date)
          .firstOrDefault();

        return lastReading?.value ?? 0;
      })
      .toArray();

    return lastReadings.reduce((acc, cur) => acc + cur, 0);
  }, [devicesQuery.data, devicesReadingsQuery.data]);

  const uomOptions = useMemo(() => {
    const result = devicesQuery.data
      ?.map((x) => ({ label: x.uoM, value: x.uoM }))
      .filter((x, i, a) => a.findIndex((y) => y.value === x.value) === i);
    return result;
  }, [devicesQuery.data]);

  const uomRowFilterTemplate = useCallback(
    (options: ColumnFilterElementTemplateOptions) => (
      <MultiSelect
        options={uomOptions}
        value={options.value}
        onChange={(e) => {
          options.filterApplyCallback(e.value);
        }}
        placeholder={"UoM"}
        maxSelectedLabels={1}
      />
    ),
    [uomOptions]
  );
  const recorderOptions = useMemo(() => {
    const result = devicesQuery.data
      ?.map((x) => ({ label: x.recorder?.tag, value: x.recorder?.id }))
      .filter((x, i, a) => a.findIndex((y) => y.value === x.value) === i);
    return result;
  }, [devicesQuery.data]);

  const recorderRowFilterTemplate = useCallback(
    (options: ColumnFilterElementTemplateOptions) => (
      <MultiSelect
        options={recorderOptions}
        value={options.value}
        onChange={(e) => {
          options.filterApplyCallback(e.value);
        }}
        placeholder={"Recorder"}
        maxSelectedLabels={1}
      />
    ),
    [recorderOptions]
  );

  return (
    <div className="h-full w-full flex flex-row">
      <div className="border-1 border-300 h-full flex flex-column">
        <div className="flex flex-row align-items-center">
          <Calendar
            className="p-2 w-full"
            value={dateRange[0]}
            onChange={(e) => {
              let range = dateRange;
              range[0] = e.value as Date;
              setDateRange(range);
              devicesReadingsQuery.refetch();
            }}
            selectionMode="single"
            showTime={true}
            hideOnDateTimeSelect={true}
            showButtonBar
          />
          <span>:</span>
          <Calendar
            className="p-2 w-full"
            value={dateRange[1]}
            onChange={(e) => {
              let range = dateRange;
              range[1] = e.value as Date;
              setDateRange(range);
              devicesReadingsQuery.refetch();
            }}
            showTime={true}
            hideOnDateTimeSelect={true}
            showButtonBar
            selectionMode="single"
          />
        </div>
        <div className="h-full flex">
          <DataTable
            value={devicesQuery.data ?? []}
            loading={devicesQuery.isFetching}
            selectionMode={undefined}
            selection={selectedDevices}
            scrollable={true}
            scrollHeight="calc(100% - 55px)"
            onSelectionChange={(e) => setSelectedDevices(e.value as any[])}
            dataKey="id"
            tableStyle={{ width: "34rem" }}
            filterDisplay="row"
            filters={filters}
          >
            <Column
              selectionMode="multiple"
              headerStyle={{ width: "3rem" }}
            ></Column>
            <Column
              field="recorder.id"
              filter={true}
              header="Recoder"
              filterMatchMode="in"
              body={(device: Device) => device.recorder?.tag}
              filterElement={recorderRowFilterTemplate}
              showFilterMenu={false}
              showClearButton={false}
              sortable
            ></Column>
            <Column
              field="tag"
              filter={true}
              sortable
              header="Device"
              showFilterMenu={false}
              showClearButton={false}
              filterMatchMode="custom"
              style={{ width: "50%" }}
              body={(device: Device) => (
                <div style={{ wordBreak: "keep-all" }}>
                  {device.code} {device.tag}
                </div>
              )}
            ></Column>
            <Column
              field="uoM"
              filterField="uoM"
              filter={true}
              filterMatchMode="in"
              filterElement={uomRowFilterTemplate}
              sortable
              header="UoM"
              showFilterMenu={false}
              showClearButton={false}
            ></Column>
          </DataTable>
        </div>
      </div>

      <div className="border-1 border-300 h-full w-full bg-white">
        <div
          className="absolute right-0 pt-2 pr-4 text-2xl"
          style={{ width: "30rem" }}
        >
          <div className="flex flex-column">
            <div className="flex flex-row">
              <div className="w-8 text-right">Total:</div>
              <div className="w-4 font-bold pl-2">
                {selectedTotalValue?.toFixed(2) ?? "0.00"}
              </div>
            </div>

            <div className="flex flex-row">
              <div className="w-8 text-right">Last readings total:</div>
              <div className="w-4 font-bold pl-2">
                {selectedLastReadingTotalValue?.toFixed(2) ?? "N/A"}
              </div>
            </div>
          </div>
        </div>
        <DevicesChartComponent
          deviceReadings={devicesReadingsQuery.data ?? []}
          devices={selectedDevices}
        />
      </div>
    </div>
  );
}
